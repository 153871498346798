<template>
  <div class="page">
    <div class="title">薪资核算</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- picker -->
        发薪月份：<el-date-picker
          v-model="search.date"
          type="month"
          value-format="yyyy-MM"
        >
        </el-date-picker>
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
    </div>
    <div class="filter_box">
      <div class="filter_search">
        <div class="custom_button no_select" @click="taxs()">个税扣除</div>
        <div class="custom_button plain no_select" @click="downloadTamplate">
          下载导入模板
        </div>
        <div class="custom_button no_select" @click="chooseExcel">
          导入员工工资
        </div>
        <div class="custom_button no_select">导出</div>
        <input
          ref="fileInput"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          @change="importSalary"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
        <div class="custom_button no_select" @click="dialogFormVisible2 = true">
          薪资发放
        </div>
      </div>
    </div>
    <!-- table -->
    <el-table
      id="table"
      ref="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="staff.real_name"
        label="姓名"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="staff.labour_type_value"
        label="用工形式"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="company.name"
        label="合同公司"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="staff.department_data.station_name"
        label="岗位"
        fixed="left"
      ></el-table-column>
      <el-table-column label="应发">
        <el-table-column prop="base_pay" label="基本工资"></el-table-column>
        <el-table-column prop="overtime_pay" label="加班工资">
        </el-table-column>
        <el-table-column
          prop="grant_1"
          :label="tableHeader.grant_1 || '绩效奖金'"
        >
        </el-table-column>
        <el-table-column
          prop="grant_2"
          :label="tableHeader.grant_2 || '高温津贴'"
        >
        </el-table-column>
        <el-table-column
          prop="grant_other"
          :label="tableHeader.grant_other || '其他'"
        ></el-table-column>
        <el-table-column prop="grant_subtotal" label="小计"></el-table-column>
      </el-table-column>
      <el-table-column label="应扣">
        <el-table-column
          prop="deduct_1"
          :label="tableHeader.deduct_1 || '社保'"
        ></el-table-column>
        <el-table-column
          prop="deduct_2"
          :label="tableHeader.deduct_2 || '公积金'"
        >
        </el-table-column>
        <el-table-column
          prop="deduct_3"
          :label="tableHeader.deduct_3 || '水电'"
        ></el-table-column>
        <el-table-column
          prop="deduct_4"
          :label="tableHeader.deduct_4 || '借支'"
        ></el-table-column>
        <el-table-column
          prop="deduct_other"
          :label="tableHeader.deduct_other || '其他'"
        ></el-table-column>
        <el-table-column prop="deduct_subtotal" label="小计"> </el-table-column>
      </el-table-column>
      <el-table-column
        prop="tax"
        :label="tableHeader.tax || '税点'"
      ></el-table-column>
      <el-table-column
        prop="deduct_social_security"
        :label="tableHeader.deduct_social_security || '单位缴纳部分社保费用'"
      ></el-table-column>
      <el-table-column
        prop="deduct_accumulation_fund"
        :label="
          tableHeader.deduct_accumulation_fund || '单位缴纳部分公积金费用'
        "
      ></el-table-column>
      <el-table-column prop="final_pay" label="实发"></el-table-column>
      <el-table-column label="操作" min-width="200" fixed="right">
        <template v-slot:default="scope">
          <el-dropdown size="mini" split-button type="primary">
            操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="scope.row.status == 0"
                @click.native="
                  $router.push({
                    name: 'SalaryAuditEditor',
                    params: {
                      id: scope.row.id,
                      date: search.date,
                    },
                  })
                "
                >编辑</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.status == 0"
                @click.native="check(scope.row.id)"
                >核算确认
              </el-dropdown-item>
              <el-dropdown-item @click.native="del(scope.row.id)"
                >删除</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.status == 0"
                @click.native="tax(scope.row.id)"
                >个税扣除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <div class="bottom_betch_operation">
      <el-select class="betch_select" v-model="batch">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="custom_button no_select" @click="toBatchProcessing">
        批量处理
      </div>
    </div>

    <el-dialog title="个税扣除" width="40%" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="是否扣除个税：" prop="is_deduct_tax">
          <el-radio-group v-model="form.is_deduct_tax">
            <el-radio label="0">不扣除</el-radio>
            <el-radio label="1">扣除</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="扣除对象："
          prop="deduct_tax_type"
          v-if="form.is_deduct_tax == 1"
        >
          <el-radio-group v-model="form.deduct_tax_type">
            <el-radio label="1">企业</el-radio>
            <el-radio label="2">个人</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog width="80%" :visible.sync="dialogFormVisible2">
      <SalaryPayment :date="search.date"></SalaryPayment>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SalaryPayment from "./SalaryPayment";

export default {
  components: {
    SalaryPayment,
  },
  data() {
    return {
      search: {
        keyword: "",
        date: "",
      },
      tableData: [],
      selection: [],
      tableHeader: {},
      dialogFormVisible: false,
      dialogFormVisible2: false,
      form: {
        id: "",
        is_deduct_tax: "",
        deduct_tax_type: "",
      },
      rules: {
        is_deduct_tax: [
          {
            required: true,
            message: "请选择是否扣除",
            trigger: "change",
          },
        ],
        deduct_tax_type: [
          {
            required: true,
            message: "请选择扣除对象",
            trigger: "change",
          },
        ],
      },
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      batch: 1,
      options: [
        {
          value: 1,
          label: "核算确认",
        },
        {
          value: 2,
          label: "打印",
        },
        {
          value: 3,
          label: "删除",
        },
      ],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.search.date =
      sessionStorage.getItem("SalaryAuditSearchDate") ??
      this.$options.filters.formatTime(
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        "yyyy-MM"
      );
    this.getSalaryAuditHeader()
      .then((res) => {
        console.log("头部", res);
        this.tableHeader = res.data.data.detail;
      })
      .catch((e) => {
        console.error(e);
      });
    this.loadData((loadDataObj) => {
      loadDataObj.month = this.search.date;
    });
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("pay", [
      "getSalaryAuditList",
      "getSalaryAuditTemplate",
      "postImportSalary",
      "getSalaryAuditHeader",
      "delSalaryAudit",
      "confirmSalaryAudit",
      "taxSalaryAudit",
    ]),
    loadData(func) {
      console.log(this.loadDataObj);
      // 单例初始化
      if (!this.loadDataObj)
        this.loadDataObj = new LoadDataClass(0, this.getSalaryAuditList);

      // 通知，用于修改对象属性
      if (func) func(this.loadDataObj);

      let loading = this.$loading();
      this.loadDataObj
        .load(this.page.page)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
          sessionStorage.clear("SalaryAuditSearchDate");
          loading.close();
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      this.loadData((loadDataObj) => {
        loadDataObj.keyword = this.search.keyword;
        loadDataObj.month = this.search.date;
      });
    },

    handleSelectionChange(val) {
      this.selection = val;
    },

    /**
     * 选择excel
     */
    chooseExcel() {
      this.$refs.fileInput.click();
    },

    /**
     * 下载导入模板
     */
    downloadTamplate() {
      let loading = this.$loading();
      this.getSalaryAuditTemplate()
        .then((res) => {
          console.log(res);
          loading.close();
          let filename =
            res.headers["content-disposition"].match(/filename=(.*)/)[1];
          filename = filename.replace(/^(\s|")+|(\s|")+$/g, "");
          // 将二进制流转为blob
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
            window.navigator.msSaveBlob(blob, decodeURI(filename));
          } else {
            // 创建新的URL并指向File对象或者Blob对象的地址
            const blobURL = window.URL.createObjectURL(blob);
            // 创建a标签，用于跳转至下载链接
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", decodeURI(filename));
            // 兼容：某些浏览器不支持HTML5的download属性
            if (typeof tempLink.download === "undefined") {
              tempLink.setAttribute("target", "_blank");
            }
            // 挂载a标签
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            // 释放blob URL地址
            window.URL.revokeObjectURL(blobURL);
          }
        })
        .catch((e) => {
          loading.close();
          console.error(e);
          this.$message.error(e.msg);
        });
    },

    /**
     * 导入员工工资
     */
    async importSalary(file) {
      let loading = this.$loading();
      let link;
      try {
        link = (
          await this.uploadFile({
            file: file.target.files[0],
            type: "salary_xls",
          })
        ).data.data.link;
        let res = await this.postImportSalary(link);
        loading.close();
        console.log(res);
        this.$message.success(res.data.msg);
        this.loadData();
      } catch (e) {
        loading.close();
        console.error(e);
        this.$message.error(e.msg);
      }
    },

    del(id) {
      this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let loading = this.$loading();
        this.delSalaryAudit({
          id,
        })
          .then((res) => {
            console.log(res);
            loading.close();
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.log(e);
            loading.close();
            this.$message.error(e.msg);
          });
      });
    },

    check(id) {
      this.$confirm("核算后无法再进行拆分操作，确定进行核算吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let loading = this.$loading();
        this.confirmSalaryAudit({
          id,
        })
          .then((res) => {
            console.log(res);
            loading.close();
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.log(e);
            loading.close();
            this.$message.error(e.msg);
          });
      });
    },

    tax(id) {
      this.form.id = id;
      this.dialogFormVisible = true;
    },

    taxs() {
      let ids = [];
      if (this.selection.length == 0) {
        this.$message.error("请选择员工");
        return false;
      }
      this.selection.map((item) => {
        ids.push(item.id);
      });
      this.form.id = ids.join(",");
      this.dialogFormVisible = true;
    },

    /**
     * 批量处理
     */
    toBatchProcessing() {
      let selectedItems = this.$refs.table.store.states.selection;
      console.log(selectedItems);
      if (!(selectedItems.length > 0)) {
        this.$message.warning("请至少选择一项进行操作");
        return;
      }
      switch (this.batch) {
        case 1: // 批量核算
          this.check(selectedItems.map((item) => item.id).join(","));
          break;

        case 2:
          this.$router.push({
            name: "SalaryAuditPrint",
            params: {
              id: selectedItems.map((item) => item.id),
              date: this.search.date,
            },
          });
          break;

        case 3: // 批量删除
          this.del(selectedItems.map((item) => item.id).join(","));
          break;

        default:
          break;
      }
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          let loading = this.$loading();
          console.log(this.form);
          this.taxSalaryAudit(this.form)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },
  },
};

class LoadDataClass {
  constructor(month, request, keyword = null) {
    this.month = month;
    this.keyword = keyword;
    this.func = request;
  }

  load(page) {
    let params = {
      month: this.month,
      page,
    };
    if (this.keyword) params.keywords = this.keyword;
    return this.func(params);
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 150px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.bottom_betch_operation {
  position: absolute;
  bottom: 0;
  display: flex;

  .betch_select {
    width: 130px;
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }

    ::v-deep .el-input__icon {
      line-height: 36px;
    }
  }
}
</style>
