<template>
    <div class="page">
        <div class="title">薪资发放</div>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- picker -->
                <el-date-picker
                    v-model="search.date"
                    type="month"
                    value-format="yyyy-MM"
                ></el-date-picker>

                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">
                    搜索
                </div>
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column
                prop="month"
                label="薪资发放月份"
            ></el-table-column>
            <el-table-column
                prop="total_num"
                label="发放员工数"
            ></el-table-column>
            <el-table-column
                prop="final_pay"
                label="薪资总计"
            ></el-table-column>
            <el-table-column
                prop="actual_salary"
                label="企业应发总数"
            ></el-table-column>
            <el-table-column
                prop="pre_salary"
                label="平台应发总数"
            ></el-table-column>
            <el-table-column prop="tax" label="发放服务费"></el-table-column>
            <el-table-column
                prop="status_value"
                label="发放状态"
            ></el-table-column>
            <el-table-column prop="account" label="发放账户"></el-table-column>
            <el-table-column label="操作" min-width="120" fixed="right">
                <template slot-scope="scope">
                    <el-dropdown size="mini" split-button type="primary">
                        操作
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                @click.native="toPay(scope.row.id)"
                                >发放薪资</el-dropdown-item
                            >
                            <el-dropdown-item @click.native="check(scope.row)"
                                >查看明细</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <div class="operations">
                        <div @click="toPay(scope.row.id)">发放薪资</div>
                        <div @click="check(scope.row)">查看明细</div>
                    </div> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            search: {
                keyword: "",
                date: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
            },
            tableData: [],
            page: {
                size: 15,
                page: 1,
                total: 0,
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    props: ["date"],
    created() {
        if (this.date) this.search.date = this.date;
        this.loadData((loadDataObj) => {
            loadDataObj.month = this.search.date;
        });
    },
    watch: {
        date(val) {
            this.search.date = val;
            this.loadData((loadDataObj) => {
                loadDataObj.month = this.search.date;
            });
        },
    },
    methods: {
        ...mapActions("pay", ["getStatisticalList", "getToPayment"]),

        loadData(func) {
            // 单例初始化
            if (!this.loadDataObj)
                this.loadDataObj = new LoadDataClass(
                    0,
                    this.getStatisticalList
                );

            // 通知，用于修改对象属性
            func(this.loadDataObj);

            let loading = this.$loading();
            this.loadDataObj
                .load(this.page.page)
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    loading.close();
                })
                .catch((e) => {
                    loading.close();
                    this.$message.error(e);
                });
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            this.loadData((loadDataObj) => {
                loadDataObj.keyword = this.search.keyword;
                loadDataObj.month = this.search.date;
            });
        },

        check(row) {
            console.log(row);
        },

        /**
         * 发薪
         */
        toPay(id) {
            this.$confirm("确定进行发薪吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let loading = this.$loading();
                this.getToPayment({
                    id,
                })
                    .then((res) => {
                        console.log(res);
                        loading.close();
                        this.$message.success(res.data.msg);
                        this.loadData();
                    })
                    .catch((e) => {
                        console.log(e);
                        loading.close();
                        this.$message.error(e.msg);
                    });
            });
        },
    },
};

class LoadDataClass {
    constructor(month, request, keyword = null) {
        this.month = month;
        this.keyword = keyword;
        this.func = request;
    }

    load(page) {
        let params = {
            month: this.month,
            page,
        };
        if (this.keyword) params.keyword = this.keyword;
        return this.func(params);
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
